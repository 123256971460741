import React from "react";

const Contact = () => {
  return (
    <div className="dark:bg-slate-800">
      <section className="text-gray-600 dark:text-gray-400 body-font relative z-20">
        <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
          <div className="lg:w-2/3 md:w-1/2 bg-gray-300 dark:bg-slate-700 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
            <iframe
              width="100%"
              height="100%"
              className="absolute inset-0"
              frameBorder="0"
              title="map"
              marginHeight="0"
              marginWidth="0"
              scrolling="no"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7065.445274572897!2d85.30064052326361!3d27.694966180894504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb18518d4e8021%3A0x92981cbf20e7d934!2sTeku%2C%20Kathmandu%2044600!5e0!3m2!1sen!2snp!4v1674643513784!5m2!1sen!2snp"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>

            <div className="bg-white dark:bg-slate-700  relative flex flex-wrap py-6 rounded shadow-md">
              <div className="lg:w-1/2 px-6">
                <h2 className="title-font font-semibold text-gray-900 dark:text-white tracking-widest text-xs">
                  ADDRESS
                </h2>
                <p className="mt-1">Teku, Kathmandu, Nepal</p>
              </div>
              <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                <h2 className="title-font font-semibold text-gray-900 dark:text-white tracking-widest text-xs">
                  EMAIL
                </h2>
                <a className="text-red-500 leading-relaxed">
                  sumeetramhrjn@gmail.com
                </a>
                <h2 className="title-font font-semibold text-gray-900 dark:text-white tracking-widest text-xs mt-4">
                  PHONE
                </h2>
                <p className="leading-relaxed">9860425958</p>
              </div>
            </div>
          </div>
          <div className="lg:w-1/3 md:w-1/2 bg-white dark:bg-slate-700 p-4 rounded-lg flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
            <h2 className="text-gray-900 dark:text-white text-lg mb-1 font-medium title-font">
              Feedback
            </h2>
            <p className="leading-relaxed mb-5 text-gray-600 dark:text-gray-400">
              Leave a message for conversation
            </p>
            <div className="relative mb-4">
              <label
                htmlFor="name"
                className="leading-7 text-sm text-gray-600 dark:text-gray-400"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full bg-white rounded border border-gray-300 dark:bg-transparent focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 dark:text-gray-400 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="email"
                className="leading-7 text-sm text-gray-600 dark:text-gray-400"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full bg-white rounded border border-gray-300 dark:bg-transparent focus:border-red-500 focus:ring-2 focus:ring-red-200 text-base outline-none text-gray-700 dark:text-gray-400 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="message"
                className="leading-7 text-sm text-gray-600 dark:text-gray-400"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                className="w-full bg-white rounded border border-gray-300 dark:bg-transparent focus:border-red-500 focus:ring-2 focus:ring-red-200 h-32 text-base outline-none text-gray-700 dark:text-gray-400 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              ></textarea>
            </div>
            <button className="text-white bg-red-500 border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded text-lg">
              Send Message
            </button>
            <p className="text-xs text-gray-500 dark:text-gray-400 mt-3">
              Please contact me through email for faster reply.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
